var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"pagination"},[_vm._v(" Показано "+_vm._s(_vm.activePage * _vm.countByPage > _vm.count ? _vm.count : _vm.activePage * _vm.countByPage)+" из "+_vm._s(_vm.count)+" записей ")])]),(_vm.count > _vm.countByPage)?_c('div',{staticClass:"col-6 d-flex justify-content-end"},[_c('div',{staticClass:"page d-flex align-items-center"},[(!_vm.isFirstPage)?_c('router-link',{staticClass:"button button_full",attrs:{"to":{
          name: _vm.$route.name,
          query: { ..._vm.$route.query, page: _vm.activePage - 1 },
        }}},[_vm._v("Назад ")]):_vm._e(),_vm._l((_vm.getPagination),function(page){return _c('router-link',{key:page,staticClass:"button button_accent",class:!_vm.$route.query.page && page === 1 ? 'router-link-exact-active' : '',attrs:{"to":{
          name: _vm.$route.name,
          query: { ..._vm.$route.query, page: page },
        }}},[_vm._v(_vm._s(page)+" ")])}),(!_vm.isLastPage)?_c('router-link',{staticClass:"button button_full",attrs:{"to":{
          name: _vm.$route.name,
          query: { ..._vm.$route.query, page: _vm.activePage + 1 },
        }}},[_vm._v("Вперед ")]):_vm._e()],2)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }