<template>
  <div class="row align-items-center">
    <div class="col-6">
      <div class="pagination">
        Показано
        {{
          activePage * countByPage > count ? count : activePage * countByPage
        }}
        из {{ count }} записей
      </div>
    </div>
    <div class="col-6 d-flex justify-content-end" v-if="count > countByPage">
      <div class="page d-flex align-items-center">
        <router-link
          class="button button_full"
          v-if="!isFirstPage"
          :to="{
            name: $route.name,
            query: { ...$route.query, page: activePage - 1 },
          }"
          >Назад
        </router-link>
        <router-link
          class="button button_accent"
          :class="
            !$route.query.page && page === 1 ? 'router-link-exact-active' : ''
          "
          :to="{
            name: $route.name,
            query: { ...$route.query, page: page },
          }"
          v-for="page in getPagination"
          :key="page"
          >{{ page }}
        </router-link>
        <router-link
          class="button button_full"
          v-if="!isLastPage"
          :to="{
            name: $route.name,
            query: { ...$route.query, page: activePage + 1 },
          }"
          >Вперед
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    countByPage: {
      type: Number,
      default: 1,
    },
    activePage: {
      type: Number,
      default: 1,
    },
    count: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    isFirstPage() {
      return this.activePage === 1;
    },
    isLastPage() {
      return this.activePage === Math.ceil(this.count / this.countByPage);
    },
    getPagination() {
      let pages = [];
      const pageCounter = Math.ceil(this.count / this.countByPage);
      if (this.activePage === 1) {
        pages.push(1);
        if (pageCounter > 1) {
          pages.push(2);
        }
        if (pageCounter > 2) {
          pages.push(3);
        }
      } else if (this.activePage === pageCounter) {
        if (pageCounter - 2 > 0) {
          pages.push(this.activePage - 2);
        }
        if (pageCounter - 1 > 0) {
          pages.push(this.activePage - 1);
        }
        pages.push(this.activePage);
      } else {
        pages.push(this.activePage - 1);
        pages.push(this.activePage);
        pages.push(this.activePage + 1);
      }
      return pages;
    },
  },
};
</script>

<style scoped lang="scss">
.button {
  &_full {
    min-width: 81px;
  }

  &_accent {
    background: #ff6600;
    color: #fff;
  }

  margin: 0 7px;
  min-width: 46px;

  &.router-link-exact-active {
    background: rgba(246, 111, 77, 0.15);
    border: rgba(245, 111, 77, 0.15);
    color: #000;
    &:hover,
    &:focus,
    &:active {
      color: #000;
    }
  }
}
</style>
