<template>
  <div>
    <top-line title='Актуальные акции ГК "БН-Моторс"' />
    <h2 class="text-center">Акции</h2>
    <div class="container-fluid pb-5">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div
              class="col-12 col-sm-6 col-md-4 pb-3"
              v-for="event in getEventsByPage"
              :key="event.id"
            >
              <news-item :key="event.id" :news="event" link="events" />
            </div>
          </div>
        </div>
        <div class="col-12 pt-3">
          <pagination
            :active-page="page"
            :count="getCountEvents"
            :count-by-page="countByPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopLine from "@/components/pages/TopLine";
import NewsItem from "@/components/pages/News/NewsItem";
import Pagination from "@/components/layout/Pagination";
import { filter } from "lodash";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "EventsPage",
  components: { NewsItem, TopLine, Pagination },
  data() {
    return {
      page: 1,
      countByPage: 9,
      isTest: true,
    };
  },
  head: {
    title: function () {
      return {
        inner: this.$route.meta?.title || "Прокат авто",
      };
    },
  },
  computed: {
    ...mapGetters("content", ["getEvents"]),
    getEventsByPage() {
      return filter(
        this.getEvents,
        (event, index) =>
          index >= (this.page - 1) * this.countByPage &&
          index < this.page * this.countByPage
      );
    },
    getCountEvents() {
      return Number(this.getEvents.length);
    },
    getRoutePage() {
      return parseInt(this.$route.query?.page || 1);
    },
  },
  watch: {
    getRoutePage(val) {
      this.page = val || 1;
    },
  },
  mounted() {
    if (!this.getEvents.length) {
      this.loadEvents();
    }
    this.page = parseInt(this.$route.query?.page || 1);
  },
  methods: {
    ...mapActions("content", ["loadEvents"]),
  },
};
</script>

<style scoped lang="scss"></style>
